import {
    Box,
    Button,
    Card,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Input,
    Divider,
    TextField,
    Tooltip,Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
  } from '@mui/material';
  import ImageIcon from '@mui/icons-material/Image';
  import { Scrollbar } from '../../scrollbar';
  import SearchIcon from '@mui/icons-material/Search';
  import { useState } from 'react';
  import { useEffect } from 'react';
  import { Link as RouterLink } from 'react-router-dom';
  import { auditsApi } from '../../../api/audit-api';
  import { useAuth } from '../../../hooks/use-auth';
  import { useNavigate } from "react-router-dom";
  import { Delete as DeleteIcon, FileCopy as DuplicateIcon } from '@mui/icons-material';
  import toast from 'react-hot-toast';
  export const TcsList = (props) => {
    const { tcs , getGraphics} = props;
    const { user } = useAuth();
    const [filteredTcs, setFilteredTcs] = useState([])
    const [searchName, setSearchName] = useState([])
    const [createBool, setCreateBool] = useState(false)
    const [createTcsName, setCreateTcsName] = useState([])
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
    const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
    const [selectedTCSId, setSelectedTCSId] = useState(null);
    const navigate = useNavigate();
  
    useEffect(() => {
      setFilteredTcs(tcs)
    }, [tcs])
  
    const handleChangeSearch = event => {
      setSearchName(event.target.value)
      setFilteredTcs(tcs.filter((el) => el.name.toLowerCase().includes(event.target.value.toLowerCase())))
    }
  
    const handleCreateTcs = async event => {
      const id = await auditsApi.createAudit(user.admin_id, createTcsName, "tcs");
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/tcs/${id.id}`)
    }
  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleDuplicateClick = (id) => {
    setSelectedTCSId(id);
    setDuplicateDialogOpen(true);
  };
  const handleCancel = () => {
    setSelectedTCSId(null);
    setDuplicateDialogOpen(false);
  
  };
  const handleConfirmDuplicate  = async () => {
    try{
      const data = await auditsApi.DuplicateTCSById(user.admin_id, selectedTCSId);
      getGraphics()
      setFilteredTcs(tcs)
      setDuplicateDialogOpen(false)
      
      toast.success('TCS dupliqué');
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/tcs/${data.new_tcs_id}`)
    } catch (error) {
      console.error("Erreur lors de la duplication de l'audit", error);
    }
  }
  
    return (
      <>
        <Box
          sx={{
            m: 1,
            width: '95%',
            display: 'flex',
            justifyContent: "flex-end",
          }}
        >
          { user.access_level == 0 && (createBool === false ?
            <Button
              onClick={() => {
                setCreateBool(true)
              }}
              variant='contained'
            >
              Créer un TCS
            </Button>: 
          
            <Box>
              <TextField
                onChange={(event) => {
                  setCreateTcsName(event.target.value)
                }}
                sx={{
                  backgroundColor:'white',
                  // borderRadius: 50
                }}
                label="Entrez le nom du TCS"
              />
              <Button
                sx={{
                  m:1
                }}
                variant='contained'
                onClick={() => {
                  setCreateBool(false)
                }}
              >
                Annuler
              </Button>
              <Button
                variant='contained'
                onClick={() => {
                  handleCreateTcs()
                }}
              >
                Créer
              </Button>
            </Box>
          )}
  
        </Box>
        <Card {...props}>
          <Scrollbar>
            <Box
              sx={{
                  alignItems: 'center',
                  display: 'flex',
                  p: 2.5
                }}
            >
              <SearchIcon fontSize="small" />
              <Box
                sx={{
                  flexGrow: 1,
                  ml: 3
                }}
              >
                <Input
                  color='secondary'
                  disableUnderline
                  fullWidth
                  value={searchName}
                  onChange={handleChangeSearch}
                  placeholder="Chercher un TCS"
                />
              </Box>
            </Box>
            <Divider />
            <Table sx={{ minWidth: 700 }}>
              <TableBody>
                {tcs && tcs.length > 0 ? filteredTcs.map((tcsSingle, index) => (
                  <TableRow
                    hover
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          '& > img': {
                            flexShrink: 0,
                            height: 56,
                            width: 56
                          }
                        }}
                      >
                        {tcsSingle.image_link
                          ? (
                            <Box
                              sx={{
                                alignItems: 'center',
                                backgroundColor: 'background.default',
                                backgroundImage: `url(${tcsSingle.image_link})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                borderRadius: 1,
                                display: 'flex',
                                height: 100,
                                justifyContent: 'center',
                                overflow: 'hidden',
                                width: 100
                              }}
                            />
                          )
                          : (
                            <Box
                              sx={{
                                alignItems: 'center',
                                backgroundColor: 'background.default',
                                borderRadius: 1,
                                display: 'flex',
                                height: 100,
                                justifyContent: 'center',
                                width: 100
                              }}
                            >
                              <ImageIcon fontSize="small" />
                            </Box>
                          )}
                        <Box sx={{ ml: 3 }}>
                          <IconButton
                            component = {RouterLink}
                            to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/tcs/${tcsSingle.id}`}
                          >
                            <Typography variant="h6"
                              color='primary'
                            >
                              {tcsSingle.name}
                            </Typography>
                          </IconButton>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell  sx={{ width: '15%' }}>
                      {hoveredRowIndex === index && (
                        <>
                          <Tooltip title="Dupliquer le TCS">
                            <IconButton
                              onClick={() => handleDuplicateClick(tcsSingle.id)}
                              color="primary"
                            >
                              <DuplicateIcon />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="Supprimer le questionnaire">
                            <IconButton
                              // onClick={() => handleDeleteClick(questionnaire.id)}
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip> */}
                        </>
                      )}
                      </TableCell>
                  </TableRow>
                )) : <Typography sx={{p:3}}> Pas de TCS associés </Typography>}
              </TableBody>
              <Dialog open={duplicateDialogOpen} onClose={handleCancel}>
              <DialogTitle>Confirmation de duplication</DialogTitle>
              <DialogContent>
                Êtes-vous sûr de vouloir dupliquer ce TCS ?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  Annuler
                </Button>
                <Button onClick={handleConfirmDuplicate} color="primary">
                  Dupliquer
                </Button>
              </DialogActions>
            </Dialog>
            </Table>
          </Scrollbar>
        </Card>
      </>
    )
  };  