import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ForumIcon from '@mui/icons-material/Forum';
import {
  Box,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { useAuth } from "../../hooks/use-auth";
import { useLocation, useNavigate } from "react-router-dom";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ListIcon from "@mui/icons-material/List";
import TroubleshootOutlinedIcon from "@mui/icons-material/TroubleshootOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
 
import MenuIcon from "@mui/icons-material/Menu";
 
export const DashboardSidebar = (props) => {
  const { onClose, open, anchorEl,openSide, setOpenSide, ...other } = props;
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const [sections, setSections] = useState([]);
  const [expandedSections, setExpandedSections] = useState(null);
  const [selectedSubtitles, setSelectedSubtitles] = useState({
    Analyses: "Tableau de bord",
  });
 
  const handleOpen = () => setOpenSide(true);
  const handleClose = () => setOpenSide(false);
  const isLargeScreen = useMediaQuery("(min-width:1700px)");
 
  useEffect(() => {
    
    if (user.id_service === 0) {
      setSections([
        {
       
          title: null,
          items: [
            {
              icon: <HomeIcon fontSize="small" />,
              title: <span style={{ fontSize: '13px' }}>Tableau de bord</span>,
              path: `/${globalThis.localStorage.getItem('tenant')}/dashboard`
            }
          ]
        },
        {
          title: "Analyses",
          icon: <TroubleshootOutlinedIcon fontSize="small" />,
          items: [
            { title: "Scores", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/scores` },
            { title: "Performances",  },
          ],
        },
        {
          title: "Apprenants",
          icon: <GroupOutlinedIcon fontSize="small" />,
          items: [
            { title: "Liste des apprenants", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/customers` },
            { title: "Créer un apprenant", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/create` },
            { title: "Forum", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/forum` },
 
          ],
        },
        {
          title: "Conception",
          icon: <AssessmentOutlinedIcon fontSize="small" />,
          items: [
            { title: "Actions", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/formations` },
            { title: "Audits", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/audits` },
            { title: "TCS", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/tcs` },
            { title: "Dossiers patients", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/patients_folder` },
            { title: "Gestion des Risques",},
          ],
        },
        {
          title: "Qualité",
          icon: <VerifiedOutlinedIcon fontSize="small" />,
          items: [{ title: "Satisfaction", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/survey` }],
        },
        {
          title: "Sessions",
          icon: <Groups2OutlinedIcon fontSize="small" />,
          items: [
            { title: "Liste des sessions", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/session` },
            { title: "Agenda", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/agenda` },
 
           
          ],
        },
        {
          title : "Experts",
          icon: <WorkOutlineIcon fontSize="small" />,
 
 
          items:[
            { title: "Liste des experts", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/Experts` },
            { title: "Comité scientifique",}
          ],
        },
        {
          title: "Administration",
          icon: <AdminPanelSettingsIcon fontSize="small" />,
 
          items:[
            { title: "Liste des administrateurs",},
            { title: "Activités", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/activities` },
            { title: "Créer un admin", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/createAdmin` },
          ],
        },
        {
          title: "Réglage",
          icon: <SettingsIcon fontSize="small" />,
          items: [
          //  { title: "Créer un admin", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/createAdmin` },
       //   { title: "Personnalisation", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/Personnalisation`},
      //    { title: "Extensions",path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/Extensions` },
 
          //  { title: "Activités", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/activities` },
        //    { title: "Erreurs", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/errors` },
          //  { title: "Forum", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/forum` },
           // { title: "Facturation", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/facturation` },
            { title: "Paramètres", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/params` },
        //    { title: "Compte",  path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/compte`},
 
          ],
        },
      ]);
    } else if (user.id_service === 1) {
      setSections([
        {
          title: "Analyses",
          icon: <HomeIcon fontSize="small" />,
          items: [
            { title: "Tableau de bord", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard` },
            { title: "Agenda", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/agenda` },
          ],
        },
        {
          title: "Évaluations",
          icon: <VerifiedOutlinedIcon fontSize="small" />,
          items: [
            { title: "Audits", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/audits` },
            { title: "TCS", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/tcs` },
            { title: "Apprenants", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/customers` },
          ],
        },
        {
          title: "Sessions",
          icon: <ListIcon fontSize="small" />,
          items: [{ title: "Liste des sessions", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/session` }],
        },
        {
          title: "Autres",
          icon: <ForumIcon fontSize="small" />,
          items: [{ title: "Forum", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/forum` }],
        },
      ]);
    }
  }, [user]);
 
  const handleToggleSection = (title) => {
    setExpandedSections((prev) => (prev === title ? null : title));
  };
 
  const handleSelectSubtitle = (sectionTitle, subtitle, path) => {
    console.log(sectionTitle, subtitle, path)
    if (expandedSections != sectionTitle){
      handleToggleSection(sectionTitle);
    }
   
      setSelectedSubtitles((prev) => ({
      ...prev,
      [sectionTitle]: prev[sectionTitle] === subtitle ? subtitle : subtitle,
     
    }));
 
    if (path) {
      navigate(path);
    }
   
   
   
  };
 
  const content = (
    <Box
      {...other}
      anchorEl={anchorEl}
      sx={{
        display: "flex",
        flexDirection: "column",
        // height: "100%",
        backgroundColor: "#8282CA",
        // paddingTop: 10,
      }}
    >
      <Box
        component="img"
        sx={{
          mt: 2,
          ml: 4,
          width: "150px",
          height: "65px",
        }}
        alt="Kalanke"
        src={process.env.REACT_APP_LOGO_NAVBAR}
      />
      <Box sx={{ flexGrow: 1, p: 1, pt: 4 }}>
        {sections.map((section) => (
          <Box key={section.title} mt={1} mb={4}>
            <Box
            onClick={(e) => e.stopPropagation()}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "default", // Changer le style pour indiquer que ce n'est pas cliquable
                color: section.title && expandedSections === section.title ? "#7676C5" : "#FFFFFF",
 
                backgroundColor: section.title && expandedSections === section.title ? "#DDDDF1" : "transparent",
 
                borderRadius: 1,
                padding: section.title && expandedSections === section.title ? 1 : "0",
              }}
            >
              {section.icon}
              <Typography
                variant="h7"
                sx={{ ml: 1, fontWeight: "bold", fontSize: 14 }}
              >
                {section.title}
              </Typography>
            </Box>
            {/* {expandedSections === section.title && ( */}
              <Box sx={{ pl: 5 }}>
                {section.items.map((item) => (
                  <Typography
                    key={item.title}
                    variant="body1"
                    sx={{
                      color: "#FFFFFF",
                      mt: 2,
                      fontSize: 11,
                      fontWeight: "bold",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      ml:
                     ( (expandedSections === section.title &&  selectedSubtitles[section.title] === item.title)|| (!expandedSections && section.title ===null))                         ? -1
                          : 1,
                    }}
                    onClick={() =>
                      handleSelectSubtitle(section.title, item.title, item.path)
                    }
                  >
                    { ((expandedSections === section.title &&  selectedSubtitles[section.title] === item.title)|| (!expandedSections && section.title ===null)) && (
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "50%",
                          marginRight: 1,
                          fontWeight: "bold",
                        }}
                      />
                    )}
                    {item.title}
                  </Typography>
                ))}
              </Box>
            {/*  )} */}
          </Box>
        ))}
      </Box>
    </Box>
  );
 
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "white",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) =>
              theme.palette.mode === "dark" ? 1 : 0,
            color: process.env.REACT_APP_PRIMARY_COLOR,
            width: 210,
          },
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    );
  }
 
  return (
    <div style={{ display: "flex" }}>
      <IconButton
        color="inherit"
        aria-label="open sidebar"
        onClick={handleOpen}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={true}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "white",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) =>
              theme.palette.mode === "dark" ? 1 : 0,
            color: process.env.REACT_APP_PRIMARY_COLOR,
            width: 240,
          },
        }}
      >
        {content}
      </Drawer>
    </div>
  );
};
 
DashboardSidebar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
};
 
export default DashboardSidebar;