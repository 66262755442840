import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Toolbar,
  Typography,
  InputBase,
  Grid,
  CircularProgress,
  Badge
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { AccountPopover } from "./account-popover";
import { NotificationsPopover } from "./notifications-popover";
import ChatIcon from "@mui/icons-material/Chat";
import { useAuth } from "../../hooks/use-auth";
import Logo_Kalanke from "./../../images/Logo-Kalanke.png";
import { conversationApi } from "../../../src/api/conversation-api";

const DashboardNavbarRoot = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "openSide",
})(({ theme, openSide }) => ({
  backgroundColor: "white",
  left: openSide || [theme.breakpoints.up("lg")] ? 210 : 40,
  zIndex: theme.zIndex.drawer + 1,
  ...(theme.palette.mode === "light"
    ? { boxShadow: theme.shadows[3] }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        boxShadow: "none",
      }),
}));

const AccountButton = () => {
  const { user } = useAuth();
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => setOpenPopover(true);
  const handleClosePopover = () => setOpenPopover(false);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          ml: 0,
          mr: 2,
        }}
      >
        <Avatar
          sx={{
            height: 30,
            width: 30,
            mx: 0,
          }}
          src={user.avatar}
        />
        <Box sx={{ ml: 3, mr: 4 }}>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: "black", ml: 2, fontSize: "0.875rem" }}
          >
            {user.firstname} {user.lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: "black" }}>
            {user.id_service === 0 ? "Administrateur" : "Expert"}
          </Typography>
        </Box>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

export const DashboardNavbar = (props) => {
  const { onOpenSidebar, open, openSide, setOpenSide, ...other } = props;
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const { tenant } = useParams();
  const [messagesNotRead, setMessagesNotRead] = useState(0);
  const [socket, setSocket] = useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1700);

  const checkTenant = async () => {
    if (tenant === localStorage.getItem("tenant")) {
      setLoading(false);
    } else {
      navigate(`/not-found`);
      setLoading(false);
    }
  };

  const getNotReadMessages = async () => {
    try {
      const messageData = await conversationApi.getNotReadMessages(user.admin_id);
      setMessagesNotRead(messageData.notReadMessages);
      localStorage.setItem("messages-non-lus", messageData.notReadMessages);
    } catch (err) {
      console.error(err);
      setMessagesNotRead(0);
    }
  };

  useEffect(() => {
    checkTenant();
    getNotReadMessages();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1700);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const url = `${process.env.REACT_APP_WS_URL}subdomain/${localStorage.getItem(
      "tenant"
    )}/chat-server/`;
    const ws = new WebSocket(url);
    setSocket(ws);

    ws.onopen = () => console.log("Connected to WebSocket");
    ws.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      if (eventData.type === "chat.message") getNotReadMessages();
    };
    ws.onclose = () => console.log("Disconnected from WebSocket");

    return () => ws.close();
  }, []);

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh", position: "relative" }}
      >
        <CircularProgress size={170} style={{ position: "absolute" }} />
        <img alt="logo" src={Logo_Kalanke} style={{ width: "120px", position: "absolute" }} />
      </Grid>
    );
  }

  const commonStyles = {
    fontSize: isLargeScreen ? "1.5rem" : "1rem",
    padding: isLargeScreen ? "20px" : "10px",
  };

  return (
    <DashboardNavbarRoot openSide={openSide} {...other}>
      <Toolbar
        disableGutters
        sx={{
          backgroundColor: "white",
          maxWidth: openSide || [theme.breakpoints.up("lg")] ? "90%" : "100%",
          height: isLargeScreen ? "120px" : "100px", // Augmenter la hauteur si l'écran est large
          display: "flex",
          justifyContent: "space-between",
          padding: "0 16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: 1,
            height: isLargeScreen ? "90px" : "50px", // Augmenter la hauteur de la zone de recherche
            width: isLargeScreen ? "450px" : "300px", // Augmenter la largeur de la zone de recherche
            ...commonStyles,
          }}
        >
         <SearchIcon sx={{ color: "gray", mr: 1, fontSize: isLargeScreen ? "40px" : "24px" }} />
<InputBase placeholder="Rechercher..." sx={{ flex: 4, color: "black", fontSize: isLargeScreen ? "1.25rem" : "1rem" }} />

        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NotificationsPopover />
          <Box component={ButtonBase} sx={{ mr: 2 }}>
            <Badge
              badgeContent={messagesNotRead}
              color="error"
              sx={{
                "& .MuiBadge-badge": {
                  right: -3,
                  top: 13,
                  border: `2px solid #fff`,
                  padding: "0 4px",
                },
              }}
            >
              <ChatIcon
                sx={{ fontSize: isLargeScreen ? "35px" : "30px", color: "#8282CA" }}
                onClick={() =>
                  navigate(`/${localStorage.getItem("tenant")}/dashboard/discussion`)
                }
              />
            </Badge>
          </Box>
          <AccountButton />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
