import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Button, Divider, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { keyframes } from '@mui/system';

// Définition d'une animation pour l'apparition des cartes
const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
`;

// Composant pour afficher les plans d'abonnement
const Extention = () => {
  const [open, setOpen] = useState(false); // Gestion de l'ouverture du pop-up
  const [selectedPlan, setSelectedPlan] = useState(null); // Plan sélectionné pour affichage dans le pop-up

  const plans = [
    {
      title: 'Standard',
      price: '300€ HT',
      description: 'Tout ce qui est compris dans l’offre Standard',
      features: [
        '- 100 utilisateurs (au-delà, 2€/utilisateur supplémentaires)',
        '- 10 EPP/GdR',
        '- Accès à la boutique d’EPP et GDR',
        '- Accès au MarketPlace',
        '- Support standard',
        '- 500 requêtes IA par mois',
      ],
      buttonText: 'Choisir Standard',
      bgColor: ' rgba(214, 214, 255, 255) ', // Bleu doux
      textColor: '#333', // Blanc
    },
    {
      title: 'Pro',
      price: '450€ HT',
      description: 'Tout ce qui est compris dans l’offre Pro',
      features: [
        '- 300 utilisateurs (au-delà, 2€/utilisateur supplémentaires)',
        '- Nombre d’EPP/GdR illimité ',
        '',
        '- Support premium',
        '- 1500 requêtes IA par mois',
        '- Entraînement spécifique de l’IA',
      ],
      buttonText: 'Choisir Pro',
      bgColor: ' rgba(130, 130, 202, 255) ', // Bleu foncé
      textColor: '#fff', // Blanc
    },
    {
      title: 'Ultime',
      price: '800€ HT',
      description: 'Tout ce qui est compris dans l’offre Ultime :',
      features: [
        '- Nombre d’utilisateurs illimité',
        '- Nombre de requêtes IA illimité',
        '- Remises sur l’achat d’EPP/GdR',
        '- Remises sur l’achat de modules payants',
        'dans le MarketPlace',
        '- Analyses poussées pour IA',
        ' ',
      ],
      buttonText: 'Choisir Ultime',
      bgColor:'rgba(66, 66, 131, 255)', // Jaune doré
      textColor: '#fff', // Blanc
    },
    {
      title: 'Entreprise',
      price: 'Nous contacter',
      description: 'Offre personnalisée et adaptée à vos besoins',
      features: [],
      buttonText: 'Nous contacter',
      bgColor: '#31155d', // Bleu pastel clair
      textColor: '#fff', // Gris foncé pour contraste
    },
  ];

  const handleOpen = (plan) => {
    setSelectedPlan(plan); // Sélection du plan pour affichage
    setOpen(true); // Ouverture du pop-up
  };

  const handleClose = () => {
    setOpen(false); // Fermeture du pop-up
    setSelectedPlan(null); // Réinitialisation du plan sélectionné
  };

  return (
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      gap: 2,
      p: 3,
    }}
  >
    {plans.map((plan, index) => (
      <Card
        key={index}
        sx={{
          flex: 1,
          borderRadius: 2,
          boxShadow: 3,
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          display: 'flex',
          flexDirection: 'column', // Organisation verticale
          backgroundColor: plan.bgColor, // Applique la couleur de fond

          justifyContent: 'space-between', // Gère l'espacement du contenu
          minHeight: 400, // Fixe une hauteur minimale uniforme
        }}
      >
        {/* Contenu principal de la carte */}
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: plan.textColor }}>
            {plan.title}
          </Typography>
          <Typography variant="h6" sx={{ mt: 1, color: plan.textColor }}>
            {plan.price}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, color: plan.textColor }}>
            {plan.description}
          </Typography>
          <Divider sx={{ my: 2, backgroundColor: plan.textColor }} />
          <Box>
            {plan.features.map((feature, idx) => (
              <Typography key={idx} variant="body2" sx={{ color: plan.textColor, mb: 1 }}>
                {feature}
              </Typography>
            ))}
          </Box>
        </CardContent>
  
        {/* Section du bouton */}
        <Box
          sx={{
            marginTop: 'auto', // Force l'alignement du bouton en bas
            display: 'flex',
            justifyContent: 'center', // Centre le bouton horizontalement
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: '100%',
              maxWidth: 200, // Largeur maximale du bouton
              marginBottom: 2, // Ajoute un espace inférieur pour l'esthétique
            }}
            onClick={() => handleOpen(plan)}
          >
            {plan.buttonText}
          </Button>
        </Box>
      </Card>
    ))}
  
    {/* Dialog pour afficher les détails */}
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{selectedPlan?.title}</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Prix: {selectedPlan?.price}</Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {selectedPlan?.description}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Fonctionnalités :
        </Typography>
        <Box sx={{ mt: 1 }}>
          {selectedPlan?.features?.map((feature, index) => (
            <Typography key={index} variant="body2">
              - {feature}
            </Typography>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fermer
        </Button>
        <Button onClick={handleClose} color="primary">
          Confirmer l'offre
        </Button>
      </DialogActions>
    </Dialog>
  </Box>
  
  );
};

export default Extention;
