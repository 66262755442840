import { useRef, useState } from 'react';
import { ListItemIcon, ListItemText, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import * as XLSX from 'xlsx-js-style';
import toast from 'react-hot-toast';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../../hooks/use-auth';
import { statsApi } from '../../../api/stat-api';

export const ExportTcsStats = (props) => {
  const { tcsId, session, global } = props;
  const anchorRef = useRef(null);
  const [openExport, setOpenExport] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useAuth(); 

  const handleExportOpen = () => {
    setOpenExport(true);
  };

  const handleMenuClose = () => {
    setOpenExport(false);
  };

  const statsFirstTurn = (tableExcel, userStats) => {

    // 1er tour
    tableExcel.push(['', 'Premier tour']);
    tableExcel.push(['']);

    // Ajout du bon nombre de numero mini vignette
    const numberVignette = [''];
    session.turn_1.result.forEach((element, index) => {
      numberVignette.push(['Mini vignette n°' + (index+1)])
    })
    tableExcel.push(numberVignette)

    // Ajout des moyennes de la session
    const sessionStats = ['Moyenne de la session'];
    session.turn_1.result.forEach((element) => {
      sessionStats.push([element.mean])
    })
    tableExcel.push(sessionStats)

    // Ajout des moyennes de toutes les sessions
    const allSessionStats = ['Moyenne de toutes les sessions'];
    global.turn_1.forEach((element) => {
      allSessionStats.push([element.mean])
    })
    tableExcel.push(allSessionStats)

    // Ajout des moyennes des apprenants
    userStats[0].forEach((user) => {
      const userStats = [user.name]
      user.result.forEach((res) => {
        userStats.push([res.mean])
      })
      tableExcel.push(userStats)
    })

    // Titrage des improvements
    tableExcel.push([''])
    tableExcel.push(['', 'Liste des améliorations'])
    tableExcel.push([''])
    const improvement = ['Nom de l\'apprenant', 'Point à améliorer', 'Objectif d\'amélioration', 'Stratégie', 'Date limite', 'Commentaire']
    tableExcel.push(improvement)
    
    // Ajout des objectifs d'amélioration
    userStats[0].forEach((user) => {
      user.improvements.forEach((improv) => {
        tableExcel.push([user.name, improv.point, improv.objective, improv.strategy, improv.limi_date, improv.comment])
      })
    })

    // Titrage des notes
    tableExcel.push([''])
    tableExcel.push(['', 'Liste des interprétations des résultats'])
    tableExcel.push([''])
    const note = ['Nom de l\'apprenant', 'Points forts', 'Points à améliorer', 'Pourquoi']
    tableExcel.push(note)

    // Ajout des notes
    userStats[0].forEach((user) => {
      // if (user.notes.postive || user.notes.improvement_text || user.notes.why)
        // tableExcel.push([user.name, user.notes.postive, user.notes.improvement_text, user.notes.why])
    })

    return tableExcel;
  }

  const statsSecondTurn = (tableExcel, userStats) => {

    // 2eme tour
    tableExcel.push(['']);
    tableExcel.push(['']);
    tableExcel.push(['', 'Second tour']);
    tableExcel.push(['']);

    // Ajout du bon nombre de numero mini vignette
    const numberVignette = [''];
    session.turn_1.result.forEach((element, index) => {
      numberVignette.push(['Mini vignette n°' + (index+1)])
    })
    tableExcel.push(numberVignette)

    // Ajout des moyennes de la session
    const sessionStats = ['Moyenne de la session'];
    session.turn_2.result.forEach((element) => {
      sessionStats.push([element.mean])
    })
    tableExcel.push(sessionStats)

    // Ajout des moyennes de toutes les sessions
    const allSessionStats = ['Moyenne de toutes les sessions'];
    global.turn_2.forEach((element) => {
      allSessionStats.push([element.mean])
    })
    tableExcel.push(allSessionStats)

    // Ajout des moyennes des apprenants
    userStats[1].forEach((user) => {
      const userStats = [user.name]
      user.result.forEach((res) => {
        userStats.push([res.mean])
      })
      tableExcel.push(userStats)
    })

    // Titrage des notes
    tableExcel.push([''])
    tableExcel.push(['', 'Liste des interprétations des résultats'])
    tableExcel.push([''])
    const note = ['Nom de l\'apprenant', 'Points forts', 'Points à améliorer', 'Pourquoi']
    tableExcel.push(note)

    // Ajout des notes
    userStats[1].forEach((user) => {
      // tableExcel.push([user.name, user.notes.postive, user.notes.improvement_text, user.notes.why])
    })

    return tableExcel;
  }

  function makeCellsBold(worksheet, keywords) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
  
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        const cell = worksheet[cellAddress];
  
        if (cell && cell.v && keywords.includes(cell.v)) {
          if (!cell.s) {
            cell.s = {};
          }
          cell.s.font = { bold: true };
        }
      }
    }
  }

  function makeCellsBlack(worksheet) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
  
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        const cell = worksheet[cellAddress];
  
        if (cell && cell.v && typeof cell.v === 'string' && cell.v.includes('Mini vignette')) {
          if (!cell.s) {
            cell.s = {};
          }
          cell.s.fill = { fgColor: { rgb: '000000 '} } ;
          cell.s.font = { bold: true, color: { rgb: 'FFFFFF' } };
          cell.s.width = 30;
        }
      }
    }
  }

  const handleExportStats = async () => {
    try {
      setLoading(true);
      setOpenExport(false);
      const userStats = await statsApi.getTcsStatsForAllUsersBySession(user.admin_id, tcsId, session.global_data.start_session, session.global_data.end_session)

      let tableExcel = [];
      tableExcel = statsFirstTurn(tableExcel, userStats);
      tableExcel = statsSecondTurn(tableExcel, userStats);

      const worksheet = XLSX.utils.aoa_to_sheet(tableExcel);

      const keywordsBold = ['Premier tour', 'Second tour', 'Liste des améliorations', 'Liste des interprétations des résultats', 'Moyenne de la session', 'Moyenne de toutes les sessions',
      'Nom de l\'apprenant', 'Point à améliorer', 'Objectif d\'amélioration', 'Stratégie', 'Date limite', 'Commentaire']
      makeCellsBold(worksheet, keywordsBold)
      makeCellsBlack(worksheet)

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, 'Statistiques');
      XLSX.writeFile(wb, `tcs_${tcsId}_session_${session.global_data.start_session}_${session.global_data.end_session}.xlsx`);
      setLoading(false);
    } catch (err) {
        console.error(err);
        toast.error('Il y a eu un souci lors de l\'export des statistiques !');
        setLoading(false);
        setOpenExport(false);
      }
  }

  return (
    <>
      {loading ?
        <CircularProgress size={28} sx={{mb: 1, mr: 1}}/>
      :
      <>
        <Tooltip title="Export des stats">
          <IconButton
            onClick={handleExportOpen}
            ref={anchorRef}
            {...props}>
            <MoreHorizIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'top'
          }}
          onClose={handleMenuClose}
          open={openExport}
          PaperProps={{
            sx: {
              maxWidth: '100%',
              width: 256
            }
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top'
          }}
        >
          {loading?
            <CircularProgress />
          :
          <MenuItem onClick={handleExportStats}>
            <ListItemIcon>
              <DescriptionIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText 
              primary="Exporter les statistiques"
            />
          </MenuItem>
          }
        </Menu>
      </>
    }
    </>
  );
};
