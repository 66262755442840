import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import Customers from "../../pages/dashboard/customers";
import FeedBacks from "../../pages/dashboard/FeedBacks";

const RoleCustomersdRedirect = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  if ((user.id_service === 0 && user.access_level === 0)||(user.id_service === 0 && user.access_level === 1)) {
    return <Customers />;
  } else if (user.id_service === 1 && user.access_level === 2) {
    return <FeedBacks />;
  }
};

export default RoleCustomersdRedirect;
