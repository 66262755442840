import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Table,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  Checkbox,
  Link,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  MenuItem,
  LinearProgress
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { styled } from '@mui/system';
import PaymentForm from './credit';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Extention from '../Extensions/extensions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Assurez-vous d'importer le style CSS

const CustomCheckbox = styled(Checkbox)({
  color: '#6200EE', // Couleur par défaut
  '&.Mui-checked': {
    color: '#6200EE', // Couleur lorsqu'elle est sélectionnée
  },
});
const Compte = () => {
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [dateFilter, setDateFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [amountFilter, setAmountFilter] = useState('');
  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };
  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleAmountFilterChange = (event) => {
    setAmountFilter(event.target.value);
  };
  const filteredInvoices = [
    { id: 11, date: '25 Juin 2026', status: 'Payée', amount: '25,00 €', fileName: 'facture_001.pdf', fileUrl: 'http://localhost:3000/test/BIRANI-MEHDI___CV  - Copie.pdf' },
    { id: 10, date: '25 Mai 2026', status: 'Payée', amount: '35,00 €',fileName: 'facture_002.pdf', fileUrl: 'http://localhost:3000/test/CV%20DATA.pdf' },
    { id: 9, date: '25 Avril 2026', status: 'Échoué', amount: ' 40,00 €',fileName: 'facture_003.pdf', fileUrl: 'http://localhost:3000/test/BIRANI-MEHDI___CV .pdf' },
    // Ajoutez d'autres factures ici
  ].filter((invoice) => {
    const dateMatches = dateFilter ? invoice.date.includes(dateFilter) : true;
    const statusMatches = statusFilter ? invoice.status.includes(statusFilter) : true;
    const amountMatches = amountFilter === '' ? true : (amountFilter === 'asc' ? parseFloat(invoice.amount.split(' ')[1].replace(',', '.')) : -parseFloat(invoice.amount.split(' ')[1].replace(',', '.'))) > 0;
    return dateMatches && statusMatches && amountMatches;
  });

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleInvoiceSelection = (id) => {
    setSelectedInvoices((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((invoiceId) => invoiceId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDownload = async () => {
    if (selectedInvoices.length === 0) {
      // Afficher un message Toast si aucun fichier n'est sélectionné
      toast.error("Veuillez sélectionner un fichier à télécharger.");
      return; // Ne pas continuer le processus de téléchargement
    }
  
    if (selectedInvoices.length === 1) {
      // Si un seul fichier est sélectionné, télécharge-le normalement
      const invoice = filteredInvoices.find(i => i.id === selectedInvoices[0]);
      if (invoice) {
        const response = await fetch(invoice.fileUrl);
        const fileBlob = await response.blob();
        saveAs(fileBlob, invoice.fileName); // Téléchargement direct
      }
    } else {
      // Si plusieurs fichiers sont sélectionnés, les compresser dans un ZIP
      const zip = new JSZip();
      for (const invoiceId of selectedInvoices) {
        const invoice = filteredInvoices.find(i => i.id === invoiceId);
        if (invoice) {
          const response = await fetch(invoice.fileUrl);
          const fileBlob = await response.blob();
          zip.file(invoice.fileName, fileBlob);
        }
      }
      // Générer le fichier ZIP et le télécharger
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'factures.zip');
      });
    }
  };
  
  
  

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Sélectionner toutes les factures
      setSelectedInvoices(filteredInvoices.map((invoice) => invoice.id));
    } else {
      // Désélectionner toutes les factures
      setSelectedInvoices([]);
    }
  };


  return (
    <Box sx={{ p: 3 }}>
       <ToastContainer />
    <Typography variant="h4" gutterBottom>Mon Compte</Typography>

    {/* Section principale avec les cartes de plan et méthode de paiement */}
    <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
      
      {/* Carte Plan actuel */}
      <Card sx={{ flex: 1, borderRadius: 1, boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)', margin: 0 }}>
        <CardContent sx={{ paddingBottom: '10px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 0 }}>
            <Typography variant="h6" sx={{ mr: 1, marginBottom: 0 }}>Offre actuel (Standard) <Box
              sx={{
                backgroundColor: 'rgba(211, 211, 211, 0.5)',  
                color: '#8282ca',
                borderRadius: 1,
                padding: '2px 8px',
                fontSize: '0.9em',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '25px', // Hauteur ajustée
              }}
            >
              Annuel
            </Box></Typography>
            
            <Typography variant="h3" color="black" sx={{ fontWeight: 'bold' }}>
            18€
              <Typography variant="body1" component="span" sx={{ fontSize: '0.6em', marginLeft: 0.5 }}>
                /mois
              </Typography>
            </Typography>
           
          </Box>

          <Typography color="text.secondary" gutterBottom sx={{ marginBottom: 0 }}>Actif jusqu'au 25 Janvier 2027</Typography>

          
          <Box sx={{ mt: 5 }}>
  <Typography variant="body2">2,5 Go sur 10 Go restants</Typography>
  <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
    <LinearProgress
      variant="determinate"
      value={87} // Value of the progress bar
      sx={{
        flexGrow: 1,
        mr: 3,
        mb: 2,
        '& .MuiLinearProgress-bar': {
          backgroundColor: 87 > 50 ? 'red' : 'green', // Color based on progress
        },
      }}
    />
    <Typography
      variant="body2"
      sx={{
        position: 'absolute',
        top: -19,
        right: 0,
        fontWeight: 'bold',
      }}
    >
      {87}%
    </Typography>
  </Box>
<Box sx={{ mt: 3}}></Box>
  <Typography variant="body2">10 sur 20 membres restants</Typography>
  <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
    <LinearProgress
      variant="determinate"
      value={39} // Value of the progress bar
      sx={{
        flexGrow: 1,
        mr: 3,
        '& .MuiLinearProgress-bar': {
          backgroundColor: 39 > 50 ? 'red' : 'green', // Color based on progress
        },
      }}
    />
    <Typography
      variant="body2"
      sx={{
        position: 'absolute',
        top: -19,
        right: 0,
        fontWeight: 'bold',
      }}
    >
      {39}%
    </Typography>
  </Box>
</Box>



<Box sx={{ mt: 6 }}></Box>

<Box 
sx={{ 
  mt: 2, 
  p: 2, 
  bgcolor: '#ffe6e6', 
  borderRadius: 2, 
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-between',
  border: '1px solid #f5c2c7',
  maxWidth: 800 // Ajuste la largeur selon tes besoins
}}
>
<Box sx={{ display: 'flex', alignItems: 'center' }}>
  <InfoIcon sx={{ color: '#d32f2f', mr: 2 }} />
  <Box>
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#d32f2f' }}>
    Offre presque expiré
    </Typography>
    <Typography variant="body2" color="textSecondary">
      Votre offre est presque expiré. Veuillez le renouveler.
    </Typography>
  </Box>
</Box>
<Button variant="contained" color="error" sx={{ textTransform: 'none' }}>
  Renouveler
</Button>
</Box>

<Divider sx={{ width: '100%', my: 2, mx: 'auto', height: 1.3, backgroundColor: 'black' }} />


         
<Box
      sx={{
        mt: 6,
        p: 2,
        bgcolor: '#f5f5ff',
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        maxWidth: 250,
        ml: 'auto',
      }}
    >
      {/* Zone Flex avec Tooltip pour l'icône */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* Tooltip pour l'icône InfoIcon */}
        <Tooltip
          title="Changer d'offre permet de débloquer des fonctionnalités avancées et augmenter vos quotas."
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '16px', // Taille de la police du contenu du tooltip
                fontWeight: 'bold', // Optionnel : vous pouvez ajouter du gras
              },
            },
          }}
        >
          <InfoIcon sx={{ color: '#8282ca', cursor: 'pointer', mr: 1 }} />
        </Tooltip>

        {/* Lien avec le texte "Déclenchez le mode Pro" */}
        <Link
          href="#"
          underline="none"
          sx={{ display: 'flex', alignItems: 'center', color: '#3f51b5', fontWeight: 'bold' }}
          onClick={handleOpenDialog}
        >
          Déclenchez le mode Pro
          <OpenInNewIcon sx={{ fontSize: 'small', ml: 0.7 }} />
        </Link>
      </Box>
    </Box>
        </CardContent>
      </Card>
      <Dialog
  open={openDialog}
  onClose={handleCloseDialog}
  sx={{
    '& .MuiDialog-paper': {
      width: '90%',  // Ajustez la largeur selon vos besoins (par exemple '500px', '80%', etc.)
      maxWidth: '105%',  // Désactive la largeur maximale par défaut pour laisser libre le contrôle de la largeur
    },
  }}
>
  <DialogTitle>Choisissez une Offre</DialogTitle>
  <DialogContent>
    <Extention /> {/* Le composant qui contient les plans */}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="primary">
      Fermer
    </Button>
  </DialogActions>
</Dialog>

      {/* Carte Visa */}
      <Card sx={{ flex: 1, borderRadius: 1, boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)', color: 'white' }}>
        <CardContent>
         <PaymentForm></PaymentForm>
        </CardContent>
      </Card>
    </Box>


      {/* Historique de facturation */}
      <Card sx={{ borderRadius: 1, boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)' }}>
        <CardContent>
          <Typography variant="h6">Historique de facturation</Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Voici l'historique de vos factures.
          </Typography>
          <Divider sx={{ width: '100%', my: 2, mx: 'auto', height: 1.1, backgroundColor: 'black' }} />
          <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>

{/* Filtre par Date */}
<TextField
label="Filtrer par date"
type="date"
value={dateFilter}
onChange={handleDateFilterChange}
InputLabelProps={{
shrink: true,
}}
fullWidth
/>
{/* Filtre par Statut */}
<FormControl fullWidth variant="outlined">
  <InputLabel>Filtrer par statut</InputLabel>
  <Select value={statusFilter} onChange={handleStatusFilterChange} label="Filtrer par Statut">
    <MenuItem value="">Tout</MenuItem>
    <MenuItem value="Payée">Payée</MenuItem>
    <MenuItem value="Échoué">Échoué</MenuItem>
  </Select>
</FormControl>

{/* Filtre par Montant */}
<FormControl fullWidth variant="outlined">
  <InputLabel>Filtrer par montant</InputLabel>
  <Select value={amountFilter} onChange={handleAmountFilterChange} label="Filtrer par Montant">
    <MenuItem value="">Tout</MenuItem>
    <MenuItem value="asc">Du plus bas au plus élevé</MenuItem>
    <MenuItem value="desc">Du plus élevé au plus bas</MenuItem>
  </Select>
</FormControl>

</Box>
<Divider sx={{ width: '100%', my: 2, mx: 'auto', height: 1, backgroundColor: 'black' }} />


          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {/* Checkbox pour tout sélectionner */}
                  <Box display="flex" alignItems="center">
                    <CustomCheckbox
                     sx={{
                      color: '#c4bad3', // Couleur de la case par défaut
                      '&.Mui-checked': {
                        color: '#c4bad3', // Couleur lorsque la case est cochée
                      },
                    }}
                      checked={selectedInvoices.length === filteredInvoices.length}
                      indeterminate={
                        selectedInvoices.length > 0 &&
                        selectedInvoices.length < filteredInvoices.length
                      }
                      onChange={() => {
                        if (selectedInvoices.length === filteredInvoices.length) {
                          setSelectedInvoices([]); // Désélectionner toutes les factures
                        } else {
                          setSelectedInvoices(filteredInvoices.map(invoice => invoice.id)); // Sélectionner toutes les factures
                        }
                      }}
                    />
                    <Typography variant="body1">Facture</Typography>
                  </Box>
                </TableCell>
                <TableCell>Date</TableCell>
                <TableCell> Statut</TableCell>
                <TableCell>Montant</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredInvoices.map((invoice) => (
                <TableRow key={invoice.id}>
                  <TableCell>
                    {/* Checkbox + Texte de la facture */}
                    <Box display="flex" alignItems="center">
                      <CustomCheckbox
                       sx={{
                        color: '#6060B8', // Couleur de la case par défaut
                        '&.Mui-checked': {
                          color: '#6060B8', // Couleur lorsque la case est cochée
                        },
                      }}
                        checked={selectedInvoices.includes(invoice.id)}
                        onChange={() => handleInvoiceSelection(invoice.id)}
                      />
                    <Link 
  href={invoice.fileUrl} 
  underline="none" 
  sx={{ display: 'flex', alignItems: 'center', color: '#3f51b5', fontWeight: 'bold' }} 
  target="_blank" // Ajoutez ceci pour ouvrir dans un nouvel onglet
>
  {invoice.fileName}
  <OpenInNewIcon sx={{ fontSize: 'small', ml: 0.5 }} />
</Link>

                    </Box>
                  </TableCell>
                  <TableCell>{invoice.date}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        width: '80px',
                        px: 0.1,
                        py: 0.5,
                        borderRadius: 2,
                        backgroundColor: invoice.status === 'Payée' ? 'green' : 'red',
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      {invoice.status}
                    </Box>
                  </TableCell>
                  <TableCell>{invoice.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
              onClick={handleDownload}
            >
              Télécharger
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Compte;