class WidgetsApi {
    async setPreferences(admin_id, preferences) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/widget-preferences/save/`
        const values = {
            "admin_id": admin_id,
            "preferences": preferences
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async getAdminPreferences(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/widget-preferences/get/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async getWidgets() {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/widgets/`;
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.msg || 'Failed to fetch widgets');
        }
        return response.json();
    }

   
    

    async getFinishedEpp() {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/get_finished_epp/`;
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.msg || 'Failed to fetch finished EPPs');
        }
        return response.json();
    }


    async getBestFinishedEpp() {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/best_finished_epp/`;
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.msg || 'Failed to fetch finished EPPs');
        }
        return response.json();
    }
    async  getWidgetLocations(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/widgets/localisations/${admin_id}/`;
    const accessToken = localStorage.getItem('accessToken');
    
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.msg || 'Failed to fetch widget locations');
    }

    return response.json();
}

async deleteAllWidgetLocalisations() {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/widget-localisations/delete-all/`;
    const accessToken = localStorage.getItem('accessToken');
    
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.msg || 'Failed to delete widget localisations');
        }

        return await response.json();
    } catch (error) {
        console.error('Error deleting widget localisations:', error);
        throw error;
    }
}


    async getInProgressEpp() {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/get_in_progress_epp/`;
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.msg || 'Failed to fetch in-progress EPPs');
        }
        return response.json();
    }

    async  createWidgetLocation(admin_id, zone) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/widget-locations/create/`;
        const values = {
            "admin_id": admin_id,
            "zone": zone
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
    
    
    async getGlobalSatisfaction() {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/get_global_satisfaction/`;
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.msg || 'Failed to fetch in-progress EPPs');
        }
        return response.json();
    }
    

    async getTopAuditSatisfaction() {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/get_best_satisfaction_scores/`;
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.msg || 'Failed to fetch in-progress EPPs');
        }
        return response.json();
    }
    

    async saveWidgetLocations(admin_id, widgets) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/savelocation/send/`;
        const values = {
          admin_id, // Ajouter admin_id
          widgets,
        };
        const accessToken = globalThis.localStorage.getItem("accessToken");
      
        try {
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          return await response.json();
        } catch (error) {
          console.error("Error saving widget locations:", error);
          throw error;
        }
      }
      
    
}

export const widgetsApi = new WidgetsApi();