import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Input,
  Divider,
  TextField,
  CircularProgress,
  Tooltip,Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { Scrollbar } from '../../scrollbar';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { auditsApi } from '../../../api/audit-api';
import { useAuth } from '../../../hooks/use-auth';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { Delete as DeleteIcon, FileCopy as DuplicateIcon } from '@mui/icons-material';
export const AuditsList = (props) => {
  const { audits, isFetchLoading,getGraphics } = props;
  const { user } = useAuth();
  const [filteredAudits, setFilteredAudits] = useState([])
  const [searchName, setSearchName] = useState([])
  const [createBool, setCreateBool] = useState(false)
  const [createAuditName, setCreateAuditName] = useState([])
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [selectedAuditId, setSelectedAuditId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredAudits(audits)
  }, [audits])

  const handleChangeSearch = event => {
    setSearchName(event.target.value)
    setFilteredAudits(audits.filter((el) => el.name.toLowerCase().includes(event.target.value.toLowerCase())))
  }

  const handleCreateAudit = async event => {
    const id = await auditsApi.createAudit(user.admin_id, createAuditName, "audit");
    navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/audits/${id.id}`)
  }
  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  const handleDuplicateClick = (id) => {
    setSelectedAuditId(id);
    setDuplicateDialogOpen(true);
  };
  const handleCancel = () => {
    setSelectedAuditId(null);
    setDuplicateDialogOpen(false);
  
  };
  const handleConfirmDuplicate  = async () => {
    try{
      const data = await auditsApi.DuplicateAuditById(user.admin_id, selectedAuditId);
      setFilteredAudits(audits)
      setDuplicateDialogOpen(false)
      getGraphics()
      toast.success('Audit dupliqué');
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/audits/${data.new_audit_id}`)
    } catch (error) {
      console.error("Erreur lors de la duplication de l'audit", error);
    }
  }
  return (
    <>
      <Box
        sx={{
          m: 1,
          width: '95%',
          display: 'flex',
          justifyContent: "flex-end",
        }}
      >
        { user.access_level == 0 && (createBool === false ?
          <Button
            onClick={() => {
              setCreateBool(true)
            }}
            variant='contained'
          >
            Créer un audit
          </Button>: 
        
          <Box>
            <TextField
              onChange={(event) => {
                setCreateAuditName(event.target.value)
              }}
              sx={{
                backgroundColor:'white',
                // borderRadius: 50
              }}
              label="Entrez le nom de l'audit"
            />
            <Button
              sx={{
                m:1
              }}
              variant='contained'
              onClick={() => {
                setCreateBool(false)
              }}
            >
              Annuler
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                handleCreateAudit()
              }}
            >
              Créer
            </Button>
          </Box>
        )}

      </Box>
      <Card {...props}>
        <Scrollbar>
          <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2.5
              }}
          >
            <SearchIcon fontSize="small" />
            <Box
              sx={{
                flexGrow: 1,
                ml: 3
              }}
            >
              <Input
                color='secondary'
                disableUnderline
                fullWidth
                value={searchName}
                onChange={handleChangeSearch}
                placeholder="Chercher un audit"
              />
            </Box>
          </Box>
          <Divider />
          <Table sx={{ minWidth: 700 }}>
            <TableBody>
              {isFetchLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', height: '100px'}}>
                <CircularProgress />
              </Box>
              
              ) : (
              audits && audits.length > 0 ? filteredAudits.map((audit, index) => (
                <TableRow
                  hover
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        '& > img': {
                          flexShrink: 0,
                          height: 56,
                          width: 56
                        }
                      }}
                    >
                      {audit.image_link
                        ? (
                          <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              backgroundImage: `url(${audit.image_link})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              borderRadius: 1,
                              display: 'flex',
                              height: 100,
                              justifyContent: 'center',
                              overflow: 'hidden',
                              width: 100
                            }}
                          />
                        )
                        : (
                          <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              borderRadius: 1,
                              display: 'flex',
                              height: 100,
                              justifyContent: 'center',
                              width: 100
                            }}
                          >
                            <ImageIcon fontSize="small" />
                          </Box>
                        )}
                      <Box sx={{ ml: 3 }}>
                        <IconButton
                          component = {RouterLink}
                          to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/audits/${audit.id}`}
                        >
                          <Typography variant="h6"
                            color='primary'
                          >
                            {audit.name}
                          </Typography>
                        </IconButton>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell  sx={{ width: '15%' }}>
                      {hoveredRowIndex === index && (
                        <>
                          <Tooltip title="Dupliquer l'audit">
                            <IconButton
                              onClick={() => handleDuplicateClick(audit.id)}
                              color="primary"
                            >
                              <DuplicateIcon />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="Supprimer le questionnaire">
                            <IconButton
                              // onClick={() => handleDeleteClick(questionnaire.id)}
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip> */}
                        </>
                      )}
                      </TableCell>
                </TableRow>
              )) : <Typography sx={{p:3}}> Pas d'audits associés </Typography>)}
            </TableBody>
            <Dialog open={duplicateDialogOpen} onClose={handleCancel}>
              <DialogTitle>Confirmation de duplication</DialogTitle>
              <DialogContent>
                Êtes-vous sûr de vouloir dupliquer cet audit ?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  Annuler
                </Button>
                <Button onClick={handleConfirmDuplicate} color="primary">
                  Dupliquer
                </Button>
              </DialogActions>
            </Dialog>
          </Table>
        </Scrollbar>
      </Card>
    </>
  )
};
